import React from "react";
import ReactDOM from "react-dom/client";
import { Routes } from "@generouted/react-router/lazy";
// don't change this casing or shit will break.
// ignore the error.
import AuthStore from "@/context/Auth";
import { ToastStore } from "@/context/ToastStore";

import "@/index.css"; // tailwindcss
import "@/locales/i18n"; // i18next
import "@fontsource-variable/dm-sans"; // fontsource Google Fonts "DM Sans"
import "@/apis.css"; // Novo Api font

import * as Sentry from "@sentry/react";

if (import.meta.env.VITE_FB_ENV === "prod") {
  Sentry.init({
    dsn: "https://a24e5988088119de38f88c06fc627190@o4505720600002560.ingest.sentry.io/4506826634559488",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
} else if (import.meta.env.VITE_FB_ENV === "staging") {
  Sentry.init({
    dsn: "https://f1a32a5c02d677723f5378468c5afbc0@o4505720600002560.ingest.sentry.io/4506826625646592",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AuthStore>
      <ToastStore>
        <Routes />
      </ToastStore>
    </AuthStore>
  </React.StrictMode>
);
