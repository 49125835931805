// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
  connectFirestoreEmulator,
  getFirestore,
  initializeFirestore,
  setLogLevel,
} from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Note: these are public keys, so don't worry about them being here

// THIS WILL BE PROD!
const firebaseConfig =
  import.meta.env.VITE_FB_ENV === "prod"
    ? {
        apiKey: "AIzaSyAG0kEp9eenayAA_Hmlswlkpp4_Hv0Sqd4",
        authDomain: "qwst-once-weekly-icodec.firebaseapp.com",
        projectId: "qwst-once-weekly-icodec",
        storageBucket: "qwst-once-weekly-icodec.appspot.com",
        messagingSenderId: "78151949925",
        appId: "1:78151949925:web:0f3e38b9b69ca5baae708c",
        measurementId: "G-J8N7KDECJX",
      }
    : {
        apiKey: "AIzaSyD7OZg2hZwv59oXWaeqphfQuddJl7_oySA",
        authDomain: "qwst-staging.firebaseapp.com",
        projectId: "qwst-staging",
        storageBucket: "qwst-staging.appspot.com",
        messagingSenderId: "206979076025",
        appId: "1:206979076025:web:9a0a90e841c32fad90b068",
        measurementId: "G-23KE7521E7",
      };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// setLogLevel("debug");

const emulatorHost = "0.0.0.0";

// analytics
export let analytics: Analytics | null = null;
if (import.meta.env.PROD) {
  analytics = getAnalytics(app);
}

// auth
export const auth = getAuth(app);
if (import.meta.env.DEV) {
  console.log("connecting to auth emulator");
  connectAuthEmulator(auth, `http://${emulatorHost}:9099`);
}

// db
// export const db = import.meta.env.DEV
//   ? initializeFirestore(app, {
//       experimentalForceLongPolling: true,
//       useFetchStreams: false,
//     })
//   : getFirestore(app);
export const db = getFirestore(app);
if (import.meta.env.DEV) {
  console.log("connecting to firestore emulator");
  connectFirestoreEmulator(db, emulatorHost, 9097);
}

// functions
// const functions = getFunctions(app);
// if (import.meta.env.DEV) {
//   connectFunctionsEmulator(functions, "localhost", 5001);
// }

// storage
export const storage = getStorage(app);
if (import.meta.env.DEV) {
  connectStorageEmulator(storage, emulatorHost, 9199);
}
